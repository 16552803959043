@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-dark-grey: #f4f4f431;
  --primary-orange: #f9ab00;
  --primary-dark: rgba(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-green: #3fd802d5;
}

body {
  font-family: "Roboto Condensed" sans-serif !important;
}

/****Navbar CSS ****/

nav {
  z-index: 2;
}

.logo {
  padding: -1px;
  margin-top: -45px;
}

/*----- Menu Links -----*/

nav a.nav-link {
  font-size: .8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav>li>a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #ffffff !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid rgba(52, 58, 64);
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

@media(max-width: 768px) {
  .navbar-brand {
    width: 5rem;
  }
}

.navbar-nav>li>a.active, .navbar-nav>li>a.active:focus {
  border-top: 0.1875rem solid #f9ab00;
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav>li>a:hover {
  border-top: 0.1875rem solid #f9ab00;
  border-top: 0.1875rem solid var(--primary-orange);
}

/****Header****/

.header-wrapper {
  position: relative;
  background: url(/static/media/wall-and-laptop-background.f9e274f4.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.main-info {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-family: "Roboto Condesed", sans-serif;
  z-index: 1;
}

.main-info h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

.main-info h2 {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

/*----------Typed Text--------------*/

.typed-text {
  font-size: 2rem;
  color: #ffffff;
  color: var(--primary-white);
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  @media(max-width: 768px) {
    .typed-text {
      font-size: 1.3rem !important
    }
  }
}

/*----------Header Main Offer Button--------------*/

.btn-main-offer {
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #ffffff;
  color: var(--primary-white);
  margin-top: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  color: #ffffff;
  color: var(--primary-white);
  background-color: #f33501;
  background-color: var(--primary-hover-red);
  transition: .2s ease-in-out;
}

/*****ParticlesJS******/

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/**********Author image**********/

.photo-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.author {
  width: 400px;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: #f9ab00;
  color: var(--primary-orange);
}

@media(max-width: 468px) {
  .author {
    width: 10rem;
  }
}

/***Skills***/

.skills {
  text-align: center;
  padding-bottom: 2rem;
  background: #f4f4f4;
  background: var(--primary-light-grey);
}

.skills h1 {
  color: rgba(52, 58, 64);
  color: var(--primary-dark);
  text-transform: uppercase;
}

.skills .firstRow {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.skills .secondRow {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.skills .circle {
  position: relative;
  margin: 0.375rem auto;
  background: #f9ab00;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.skills .icon {
  font-size: 2.0rem;
  color: #ffffff;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%)
}

.skills .box {
  height: 18rem;
  border-bottom: 0.3125rem solid #f9ab00;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.650rem;
  margin-bottom: 1.875rem;
  background: #ffffff;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.skills .box:hover {
  background: #f9ab00;
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid rgba(52, 58, 64);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.skills .box:hover .icon {
  color: rgba(52, 58, 64);
  color: var(--primary-dark);
}

.skills .box:hover .circle {
  background: #ffffff;
  background: var(--primary-white);
}

@media(max-width: 768px) {
  .skills h3 {
    font-size: 1.5rem;
  }
}

/********Portfolio*******/

.portfolio-wrapper {
  background: #f4f4f4;
  background: var(--primary-light-grey);
  padding: 3rem 0;
}

.portfolio-image {
  width: 15 rem;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin: 1rem;
}

/*------GitHub Box------*/

.github {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1rem;
}

.github :hover {
  background: #f9ab00;
  background: var(--primary-orange);
  border-radius: 7rem;
  border-width: -0.5rem;
}

/*-------- Overflow Box ---------*/

.overflow {
  position: absolute;
  opacity: 1;
  background: #000000;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

/*---------Contacts------*/

.contact {
  background: rgba(52, 58, 64);
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contact h1, h4, h5 {
  color: #f9ab00;
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contact p {
  color: #f4f4f4;
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

.hirebox {
  color: #f9ab00;
  color: var(--primary-orange);
}

.emailbox{
  display: -webkit-flex;
  display: flex;

  -webkit-align-items: center;

          align-items: center;
}

.mediabox {
  float: right;
}

.mediabox h3 {
  color: #f9ab00;
  color: var(--primary-orange);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.socialmedia {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  list-style-type: none;
}

.socialmedia li {
  margin-right: 0.7rem;
}


/*---------Footer--------*/

.footer{
  background: #000000;
  background: var(--primary-black);
  color: #f9ab00;
  color: var(--primary-orange);
}
/*---------Media---------*/

@media (max-width: 768px) {
  .contact {
    overflow-x: hidden;
  }
}
